





import { SpotRoleAggregationWidgetAdapterPropsMixin } from '@/features/core/components/spot-role-aggregation-widget-adapter/mixin';
import {
  SpotRoleAggregationCounterConfig,
  counterConfigCurrent,
  counterConfigLastYear,
  counterConfigLastMonth,
} from '@/features/core/components/spot-role-aggregation-widget-counter-control/model';
import { EnumProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class GeneralMeterHotWaterMeterAggregationWidget extends Mixins(
  SpotRoleAggregationWidgetAdapterPropsMixin,
) {
  @EnumProp('BLUE', 'RED')
  private readonly digitColor!: string;

  private get configs(): SpotRoleAggregationCounterConfig[] {
    return [
      counterConfigLastMonth('currentVolume', { digitColor: this.digitColor }),
      counterConfigLastYear('currentVolume', { digitColor: this.digitColor }),
      counterConfigCurrent('currentVolume', { digitColor: this.digitColor }),
    ];
  }
}
